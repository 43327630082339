var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "popup_wrap", staticStyle: { width: "800px" } },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.layerClose.apply(null, arguments)
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(_vm._s(_vm.$t("msg.CSBK140.007"))),
        ]),
        _c("div", { staticClass: "pop_scroll" }, [
          _c("div", { staticClass: "content_box" }, [
            _c("table", { staticClass: "tbl_row" }, [
              _vm._m(0),
              _vm.list.length > 0
                ? _c("tbody", [
                    _c("tr", [
                      _c("th", [_vm._v("REQ No.")]),
                      _c("td", [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.param,
                                expression: "param",
                              },
                            ],
                            on: {
                              change: [
                                function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.param = $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                },
                                function ($event) {
                                  return _vm.searchData(_vm.param)
                                },
                              ],
                            },
                          },
                          _vm._l(_vm.detail, function (item, idx) {
                            return _c(
                              "option",
                              { key: idx, domProps: { value: item } },
                              [_vm._v(_vm._s(item))]
                            )
                          }),
                          0
                        ),
                      ]),
                      _c("td", {
                        staticClass: "border_left_none",
                        attrs: { colspan: "2" },
                      }),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v(_vm._s(_vm.$t("msg.FARE010T010.001")))]),
                      _c("td", [_vm._v(_vm._s(_vm.list[0].polPortNm))]),
                      _c("th", [_vm._v(_vm._s(_vm.$t("msg.FARE010T010.002")))]),
                      _c("td", [_vm._v(_vm._s(_vm.list[0].podPortNm))]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v(_vm._s(_vm.$t("msg.FARE020T010.024")))]),
                      _c("td", { attrs: { colspan: "3" } }, [
                        _vm._v(_vm._s(_vm.list[0].cntrTypCd)),
                      ]),
                    ]),
                  ])
                : _vm._e(),
            ]),
            _c("table", { staticClass: "tbl_col mt10" }, [
              _vm._m(1),
              _vm._m(2),
              _vm.list.length > 0
                ? _c(
                    "tbody",
                    _vm._l(_vm.list, function (listItem, listIdx) {
                      return _c("tr", { key: listIdx }, [
                        _c("td", [_vm._v(_vm._s(listItem.imdunNo))]),
                        _c("td", [_vm._v(_vm._s(listItem.imoClasCd))]),
                        _c("td", [_vm._v(_vm._s(listItem.sbRiskVal1))]),
                        _c("td", [_vm._v(_vm._s(listItem.sbRiskVal2))]),
                        _c("td", [_vm._v(_vm._s(listItem.dsc))]),
                        _c("td", [_vm._v(_vm._s(listItem.imdgSeq))]),
                      ])
                    }),
                    0
                  )
                : _vm._e(),
            ]),
            _c("table", { staticClass: "tbl_row mt10" }, [
              _vm._m(3),
              _vm.list.length > 0
                ? _c("tbody", [
                    _c("tr", [
                      _c("th", [_vm._v(_vm._s(_vm.$t("msg.SPEC030.026")))]),
                      _c("td", [
                        _vm.list[0].apvStsCd === "01"
                          ? _c("span", { staticClass: "label green md" }, [
                              _vm._v(_vm._s(_vm.$t("msg.CSBK140.001"))),
                            ])
                          : _vm.list[0].apvStsCd === "02"
                          ? _c("span", { staticClass: "label blue md" }, [
                              _vm._v(_vm._s(_vm.$t("msg.CSBK140.002"))),
                            ])
                          : _vm.list[0].apvStsCd === "03"
                          ? _c("span", { staticClass: "label red md" }, [
                              _vm._v(_vm._s(_vm.$t("msg.CSBK140.003"))),
                            ])
                          : _vm.list[0].apvStsCd === "04"
                          ? _c("span", { staticClass: "label gray md" }, [
                              _vm._v(_vm._s(_vm.$t("msg.CSBK140.004"))),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v(_vm._s(_vm.$t("msg.SPEC030.044")))]),
                      _c("td", [_vm._v(_vm._s(_vm.list[0].rmk))]),
                    ]),
                  ])
                : _vm._e(),
            ]),
            _c("p", { staticClass: "txt_desc emp" }, [
              _vm._v(" " + _vm._s(_vm.$t("msg.SPEC010G010.007")) + " "),
              _c("br"),
              _vm._v(" " + _vm._s(_vm.$t("msg.SPEC010G010.008")) + " "),
            ]),
          ]),
          _c("div", { staticClass: "mt10 text_center" }, [
            _c(
              "a",
              {
                staticClass: "button gray lg",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.layerClose.apply(null, arguments)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("msg.SPEC010G010.010")))]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "150px" } }),
      _c("col", { attrs: { width: "*" } }),
      _c("col", { attrs: { width: "150px" } }),
      _c("col", { attrs: { width: "*" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "14%" } }),
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "14%" } }),
      _c("col", { attrs: { width: "14%" } }),
      _c("col", { attrs: { width: "38%" } }),
      _c("col", { attrs: { width: "10%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("UN NO")]),
        _c("th", [_vm._v("CLASS")]),
        _c("th", [_vm._v("SUB RISK 1")]),
        _c("th", [_vm._v("SUB RISK 2")]),
        _c("th", [_vm._v("PROPER SHIPPING NAME")]),
        _c("th", [_vm._v("SEQ")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "150px" } }),
      _c("col", { attrs: { width: "*" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }