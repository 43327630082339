<template>
  <div class="popup_wrap" style="width:800px;">
    <!-- popup_wrap : style="width:800px; height:600px;" 참고용, 개발시 제거 -->
    <button class="layer_close" @click.prevent="layerClose">close</button>
    <div class="popup_cont">
      <!-- popup_cont -->
      <h1 class="page_title">{{ $t('msg.CSBK140.007') }}</h1>
      <div class="pop_scroll">
        <div class="content_box">
          <!-- content_box -->
          <table class="tbl_row">
            <colgroup>
              <col width="150px"><col width="*"><col width="150px"><col width="*">
            </colgroup>
            <tbody v-if="list.length > 0">
              <tr>
                <th>REQ No.</th>
                <td>
                  <select v-model="param" @change="searchData(param)">
                    <option v-for="(item, idx) in detail" :key="idx" :value="item">{{ item }}</option>
                  </select>
                </td>
                <td colspan="2" class="border_left_none"></td>
              </tr>
              <tr>
                <th>{{ $t('msg.FARE010T010.001') }}</th>
                <td>{{ list[0].polPortNm }}</td>
                <th>{{ $t('msg.FARE010T010.002') }}</th>
                <td>{{ list[0].podPortNm }}</td>
              </tr>
              <tr>
                <th>{{ $t('msg.FARE020T010.024') }}</th>
                <td colspan="3">{{ list[0].cntrTypCd }}</td>
              </tr>
            </tbody>
          </table>
          <table class="tbl_col mt10">
            <colgroup>
              <col width="14%">
              <col width="10%">
              <col width="14%">
              <col width="14%">
              <col width="38%">
              <col width="10%">
            </colgroup>
            <thead>
              <tr>
                <th>UN NO</th>
                <th>CLASS</th>
                <th>SUB RISK 1</th>
                <th>SUB RISK 2</th>
                <th>PROPER SHIPPING NAME</th>
                <th>SEQ</th>
              </tr>
            </thead>
            <tbody v-if="list.length > 0">
              <tr v-for="(listItem, listIdx) in list" :key="listIdx">
                <td>{{ listItem.imdunNo }}</td>
                <td>{{ listItem.imoClasCd }}</td>
                <td>{{ listItem.sbRiskVal1 }}</td>
                <td>{{ listItem.sbRiskVal2 }}</td>
                <td>{{ listItem.dsc }}</td>
                <td>{{ listItem.imdgSeq }}</td>
              </tr>
            </tbody>
          </table>
          <table class="tbl_row mt10">
            <colgroup>
              <col width="150px"><col width="*">
            </colgroup>
            <tbody v-if="list.length > 0">
              <tr>
                <th>{{ $t('msg.SPEC030.026') }}</th>
                <td>
                  <span v-if="list[0].apvStsCd ==='01'" class="label green md">{{ $t('msg.CSBK140.001') }}</span>
                  <span v-else-if="list[0].apvStsCd ==='02'" class="label blue md">{{ $t('msg.CSBK140.002') }}</span>
                  <span v-else-if="list[0].apvStsCd ==='03'" class="label red md">{{ $t('msg.CSBK140.003') }}</span>
                  <span v-else-if="list[0].apvStsCd ==='04'" class="label gray md">{{ $t('msg.CSBK140.004') }}</span>
                </td>
              </tr>
              <tr>
                <th>{{ $t('msg.SPEC030.044') }}</th>
                <td>{{ list[0].rmk }}</td>
              </tr>
            </tbody>
          </table>
          <p class="txt_desc emp">
            {{ $t('msg.SPEC010G010.007') }} <br>
            {{ $t('msg.SPEC010G010.008') }}
          <!-- UNNO 3166, 3171에 한하여 Flat rack 컨테이너로, UNNO 2211, 3314에 한하여 Open top 컨테이너로 운송이 가능합니다.<br>
          위험물의 Reefer 선적은 RF위험물 사전승인 메뉴를 이용해주시기 바랍니다. -->
          </p>
        </div><!-- content_box // -->
        <div class="mt10 text_center">
          <a class="button gray lg" @click.prevent="layerClose">{{ $t('msg.SPEC010G010.010') }}</a>
        </div>
      </div>
    </div><!-- popup_cont -->
  </div><!-- popup_wrap // -->
</template>

<script>
import dangerShipList from '@/api/rest/trans/dangerShipList'
import { rootComputed } from '@/store/helpers'

export default {
  name: 'DangerQstDtlPop',
  components: {
  },
  props: {
    detail: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data: function () {
    return {
      param: '',
      list: []
    }
  },
  computed: {
    ...rootComputed
  },
  created () {
  },
  mounted: function () {
    const $vm = this
    console.log('this.detail = ' + this.detail)
    //console.log('$vm.detail.length = ' + $vm.detail.length)
    this.param = this.detail[0]
    $vm.searchData(this.param)
  },
  methods: {
    searchData: function (param) {
      dangerShipList.getDangerShipDtl(param).then(response => {
        console.log('searchData ::: ', JSON.stringify(response.data))
        this.list = response.data.dgList

        // 출발, 도착 name 수정
        let polPortNm = this.list[0].polPortNm
        let podPortNm = this.list[0].podPortNm
        polPortNm = polPortNm.split(',')
        podPortNm = podPortNm.split(',')

        polPortNm = polPortNm[0] + ',' + this.list[0].polPortCtrCd
        podPortNm = podPortNm[0] + ',' + this.list[0].podPortCtrCd

        this.list[0].polPortNm = polPortNm
        this.list[0].podPortNm = podPortNm
      }).catch(err => {
        console.log(err)
      })
    },
    layerClose: function () {
      this.$emit('close', 'X')
      return false
    }
  }
}
</script>
